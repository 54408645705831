import { Layout, Row, Col, Typography } from "antd";
import useWindowSize from "#helpers/hooks/windowSize";
import Navbar from "../Navbar/Presentational";
import Footer from "../Footer/Presentational";
import Copy from "../shared/Copy/Presentational";
import Contact from "../shared/Contact/Presesntational";
import i18n from "../../../i18n";
import TeamBlock from "./TeamBlock/Presentational";
import teamPics from "../../../assets/team";

export default function Presentational() {
  const { Title } = Typography;
  const size = useWindowSize();

  const getTeamMemberLocales = memberKey => {
    const memberPath = `team.members.${memberKey}`;
    return {
      name: i18n.t(`${memberPath}.name`),
      role: i18n.t(`${memberPath}.role`),
      description: i18n.t(`${memberPath}.description`),
      linkedIn: i18n.t(`${memberPath}.linked_id`)
    };
  };

  const teamMembers = [
    {
      name: getTeamMemberLocales("philippe_teissonniere").name,
      profilePic: teamPics.philippe1,
      secondaryProfilePic: teamPics.philippe2,
      role: getTeamMemberLocales("philippe_teissonniere").role,
      description: getTeamMemberLocales("philippe_teissonniere").description,
      linkedIn: getTeamMemberLocales("philippe_teissonniere").linkedIn
    },
    {
      name: getTeamMemberLocales("alexander_di_chiara").name,
      profilePic: teamPics.alex1,
      secondaryProfilePic: teamPics.alex2,
      role: getTeamMemberLocales("alexander_di_chiara").role,
      description: getTeamMemberLocales("alexander_di_chiara").description,
      linkedIn: getTeamMemberLocales("alexander_di_chiara").linkedIn
    },
    {
      name: getTeamMemberLocales("silvan_fornaro").name,
      profilePic: teamPics.silvan1,
      secondaryProfilePic: teamPics.silvan2,
      role: getTeamMemberLocales("silvan_fornaro").role,
      description: getTeamMemberLocales("silvan_fornaro").description,
      linkedIn: getTeamMemberLocales("silvan_fornaro").linkedIn
    },
    {
      name: getTeamMemberLocales("laisa_sigel").name,
      profilePic: teamPics.laisa1,
      secondaryProfilePic: teamPics.laisa2,
      role: getTeamMemberLocales("laisa_sigel").role,
      description: getTeamMemberLocales("laisa_sigel").description,
      linkedIn: getTeamMemberLocales("laisa_sigel").linkedIn
    },
    {
      name: getTeamMemberLocales("simone_de_toni").name,
      profilePic: teamPics.simo1,
      secondaryProfilePic: teamPics.simo2,
      role: getTeamMemberLocales("simone_de_toni").role,
      description: getTeamMemberLocales("simone_de_toni").description,
      linkedIn: getTeamMemberLocales("simone_de_toni").linkedIn
    },
    {
      name: getTeamMemberLocales("alexander_oreschenko").name,
      profilePic: teamPics.alexo1,
      secondaryProfilePic: teamPics.alexo2,
      role: getTeamMemberLocales("alexander_oreschenko").role,
      description: getTeamMemberLocales("alexander_oreschenko").description,
      linkedIn: getTeamMemberLocales("alexander_oreschenko").linkedIn
    },
    {
      name: getTeamMemberLocales("mihai_birsan").name,
      profilePic: teamPics.mihai1,
      secondaryProfilePic: teamPics.mihai2,
      role: getTeamMemberLocales("mihai_birsan").role,
      description: getTeamMemberLocales("mihai_birsan").description,
      linkedIn: getTeamMemberLocales("mihai_birsan").linkedIn
    },
    {
      name: getTeamMemberLocales("fabio_viscardi").name,
      profilePic: teamPics.fabio1,
      secondaryProfilePic: teamPics.fabio2,
      role: getTeamMemberLocales("fabio_viscardi").role,
      description: getTeamMemberLocales("fabio_viscardi").description,
      linkedIn: getTeamMemberLocales("fabio_viscardi").linkedIn
    },
    {
      name: getTeamMemberLocales("nikhil_bhatia").name,
      profilePic: teamPics.nikhil1,
      secondaryProfilePic: teamPics.nikhil2,
      role: getTeamMemberLocales("nikhil_bhatia").role,
      description: getTeamMemberLocales("nikhil_bhatia").description,
      linkedIn: getTeamMemberLocales("nikhil_bhatia").linkedIn,
      width: "75%"
    },
    {
      name: getTeamMemberLocales("drini_fejzullahu").name,
      profilePic: teamPics.drini1,
      secondaryProfilePic: teamPics.drini2,
      role: getTeamMemberLocales("drini_fejzullahu").role,
      description: getTeamMemberLocales("drini_fejzullahu").description,
      linkedIn: getTeamMemberLocales("drini_fejzullahu").linkedIn
    },
    {
      name: getTeamMemberLocales("aisling_ryan").name,
      profilePic: teamPics.aisling1,
      secondaryProfilePic: teamPics.aisling2,
      role: getTeamMemberLocales("aisling_ryan").role,
      description: getTeamMemberLocales("aisling_ryan").description,
      linkedIn: getTeamMemberLocales("aisling_ryan").linkedIn
    },
    {
      name: getTeamMemberLocales("marie-christine_teissonniere").name,
      profilePic: teamPics.marieChristine1,
      secondaryProfilePic: teamPics.marieChristine2,
      role: getTeamMemberLocales("marie-christine_teissonniere").role,
      description: getTeamMemberLocales("marie-christine_teissonniere")
        .description,
      linkedIn: getTeamMemberLocales("marie-christine_teissonniere").linkedIn
    },

    {
      name: getTeamMemberLocales("mikael_steisner").name,
      profilePic: teamPics.mikael1,
      secondaryProfilePic: teamPics.mikael2,
      role: getTeamMemberLocales("mikael_steisner").role,
      description: getTeamMemberLocales("mikael_steisner").description
      //linkedIn: getTeamMemberLocales("mikael_steisner").linkedIn
    },
    {
      name: getTeamMemberLocales("nicolas_küpfer").name,
      profilePic: teamPics.nicolas1,
      secondaryProfilePic: teamPics.nicolas2,
      role: getTeamMemberLocales("nicolas_küpfer").role,
      description: getTeamMemberLocales("nicolas_küpfer").description,
      linkedIn: getTeamMemberLocales("nicolas_küpfer").linkedIn
    },
    {
      name: getTeamMemberLocales("mila_shumilina").name,
      profilePic: teamPics.mila1,
      secondaryProfilePic: teamPics.mila2,
      role: getTeamMemberLocales("mila_shumilina").role,
      description: getTeamMemberLocales("mila_shumilina").description,
      linkedIn: getTeamMemberLocales("mila_shumilina").linkedIn
    }
  ];

  const aboutUsContent = [
    {
      title: "Mission & Vision: ",
      description:
        "LEVA is revolutionizing investment management by simplifying complex processes through technology. Our mission is to empower investors and deal managers with innovative digital tools that streamline investment structuring, compliance, and investor relations. We strive to make sophisticated investment strategies manageable and accessible, transforming the financial landscape to be more transparent, efficient, and accessible for professional investors worldwide."
    },
    {
      title: "Our team: ",
      description:
        "Meet the passionate professionals at LEVA—thinkers, builders, and leaders in finance innovation. Our diverse team, with rich backgrounds in technology, finance, and law, embodies our mission to revolutionize investment management. Each member plays a pivotal role in supporting and propelling our platform to deliver exceptional solutions. Learn more about their stories and roles as they help redefine the investment landscape and contribute to our collective mission."
    }
  ];

  return (
    <Layout className="shared-layout">
      <Row type="flex" justify="center">
        <Col className="shared-col-2" span={24}>
          <Row type="flex" justify="center">
            <Col span={20}>
              <Navbar />
            </Col>

            <Col
              xs={24}
              sm={24}
              md={18}
              lg={20}
              xl={20}
              className="team-members"
            >
              <Row type="flex" justify="center" gutter={48}>
                {/*{teamMembers.map(member => (
                  <TeamBlock member={member} />
                ))}*/}
                {aboutUsContent.map(content => (
                  <Col xs={20} sm={20} md={12} lg={12} xl={12}>
                    <div className="about-us-content">
                      <Title level={2} className={"l2-title"}>
                        {content.title}
                      </Title>
                      <Title className="text">{content.description}</Title>
                    </div>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Col>
        <Col xs={22} sm={22} md={18} lg={20} xl={20} className="team-members">
          <Row type="flex" justify="center" gutter={32}>
            {teamMembers.map(member => (
              <TeamBlock member={member} />
            ))}
          </Row>
        </Col>
      </Row>
      <Row type="flex" justify="center" className="footer">
        <Footer />
        <Copy />
      </Row>
    </Layout>
  );
}
